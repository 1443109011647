import React from 'react';
import './style/Img.css';
import dj1 from "./img/dj1.jpeg";
import dj2 from "./img/dj2.jpeg";

const Img = () => {
    return (
        <div className="container2">
        <img src={dj1} alt="サンプル画像"/>

        <img src={dj2} alt="サンプル画像"/>
        </div>
    );
}
  
  
  export default Img;
