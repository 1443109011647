import React from 'react';
import './style/schedule.css';
const Schedule = () => {
    return (
		<div className="schedule">
			<ol>
				<p>スケジュール</p>
				<li>スポットライト</li>
				<li>横浜祭</li>
				<li>世田谷祭</li>
			</ol>
		</div>
    );
}
  
export default Schedule;
  