import React from 'react';
import './style/logo.css';
import dc_logo from "./img/dc_logo.jpg";

const Logo = () => {
    return (
    	<div className="GlobalNavi">
			<div className="container">
				<img src={dc_logo} alt="サンプル画像"/>
			</div>
        </div>
        
    );
  }
  
  
  export default Logo;
  
