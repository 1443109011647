import React from 'react'
import HomeIcon from '@mui/icons-material/AdUnits';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Twitter from "./img/twitter.png"
import Music from "./img/music.png"

export const SidebarData =[
    {
    title:"Home",
    icon:<HomeIcon/>,
    link:"/home",
    },
    {
        title:"Twitter",
        icon:<img src={Twitter} alt="サンプル画像" style={{ width: "auto", height: "20px" }}/>, 
        link:"https://twitter.com/Digiconnect2023?s=20",
     },
     {
        title:"特別楽曲",
        icon:<img src={Music} alt="サンプル画像" style={{ width: "auto", height: "20px" }}/>,
        link:"/music",
     },
     {
        title:"Null",
        icon:<AttachFileIcon/>,
        link:"/file",
     },
]




