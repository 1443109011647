import React from "react";
import Logo from"./components/logo";
import Schedule from"./components/schedule";
import Sidebar from"./components/Sidebar";
import Img from"./components/Img";

const App = ()=> {
    return (
    	<div id="react" style={{ height: '100vh' }}>
			<Sidebar/>
			<Logo/>
			<Img/>
			<Schedule/>
    	</div>
    );
}

export default App;
