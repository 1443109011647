import React from 'react';
import './style/menu.css';
import {SidebarData} from './SidebarData';
import "./style/Sidebar.css";

const Sidebar = () => {
    return (
        <div className="Sidebar">
        	<ul className="SidebarList">
        		{SidebarData.map((value,key) => {
            		return(
						<li key={key} className="row" onClick={()=>{ window.location.href = value.link }}>
							<div id="icon">{ value.icon }</div>
							<div id="title">{ value.title }</div>
						</li>
					);
          		})}
          	</ul>
        </div>
    );
  }
  
  
  export default Sidebar;